@tailwind base;
@tailwind components;
@tailwind utilities;

.backgroundHeader {
  background: url("https://res.cloudinary.com/dqzpz4w3l/image/upload/q_auto:good/v1636896041/bg_l9z97d.webp");
  min-height: 100vh;
  background-size: cover;
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.7);
  background-position: center;
}

.glassBg {
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}
